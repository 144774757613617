import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { calculateDuration } from '../utils/utils';
import { useTranslation } from 'react-i18next';

import useOngoingSessions from '../hooks/useOngoingSessions';
import useTodaySessions from '../hooks/useTodaySessions';
import useYesterdaySessions from '../hooks/useYesterdaySessions';
import useMonthSessions from '../hooks/useMonthSessions';
import useYearSessions from '../hooks/useYearSessions';

import Table, { TableColumn, TableRow } from '../components/basics/table';
import ButtonGroup from '../components/buttons/ButtonGroup';
import Heading from '../components/typography/Heading';
import ErrorPage from './ErrorPage';
import { TfiLayoutLineSolid } from "react-icons/tfi";

const SessionsPage: React.FC = () => {
    // const [view, setView] = useState<"ongoing" | "today" | "yesterday" | "month" | "year">("ongoing");
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

   // Get the initial view from query params (default to "ongoing")
   const [view, setView] = useState<"ongoing" | "today" | "yesterday" | "month" | "year">(() => {
      const params = new URLSearchParams(location.search);
      return (params.get('view') as "ongoing" | "today" | "yesterday" | "month" | "year") || "ongoing";
    });

    const [loadingMore, setLoadingMore] = useState(false);
    const [offsetTop, setOffsetTop] = useState(0);
    
    // Fetch all data
    const ongoing = useOngoingSessions();
    const today = useTodaySessions();
    const yesterday = useYesterdaySessions();
    const month = useMonthSessions();
    const year = useYearSessions();

    // Get sessions from fetched data
    const sessions =
        view === "today" ? today.sessions :
        view === "yesterday" ? yesterday.sessions :
        view === "month" ? month.sessions :
        view === "year" ? year.sessions :
        ongoing.sessions;

    // Get loading from fetched data
    const loading =
        view === "today" ? today.loading :
        view === "yesterday" ? yesterday.loading :
        view === "month" ? month.loading :
        view === "year" ? year.loading :
        ongoing.loading;

    // Get error from fetched data
    const error =
        view === "today" ? today.error :
        view === "yesterday" ? yesterday.error :
        view === "month" ? month.error :
        view === "year" ? year.error :
        ongoing.error;
    
    // Get nextPage from fetched data
    const nextPage =
        view === "today" ? today.nextPage :
        view === "yesterday" ? yesterday.nextPage :
        view === "month" ? month.nextPage :
        view === "year" ? year.nextPage :
        ongoing.nextPage;
    
    // Update the URL when the view changes
    // useEffect(() => {
    //   const params = new URLSearchParams(location.search);
    //   params.set('view', view);
    //   navigate(`${location.pathname}?${params.toString()}`);
    // }, [view, navigate, location.pathname]);

    // useEffect(() => {
    //   const params = new URLSearchParams(location.search);
    //   const currentView = params.get('view') as "ongoing" | "today" | "yesterday" | "month" | "year";
    
    //   if (!currentView || currentView !== view) {
    //     // If the current view in the URL doesn't match the state, update the URL
    //     params.set('view', view);
    //     console.log('PARAMS', params)
    //     navigate(`${location.pathname}?${params.toString()}`);
    //   } else {
    //     // If the state isn't set yet (initial load), set the state from the URL
    //     setView(currentView);
    //   }
    // }, [view, navigate, location.pathname]);

    // This one reloads the page if you switch btw
    // useEffect(() => {
    //   const params = new URLSearchParams(location.search);
    //   const newView = params.get('view') as "ongoing" | "today" | "yesterday" | "month" | "year";
    
    //   if (newView && newView !== view) {
    //     setView(newView);
    //   }
    // }, [location.search]);

    useEffect(() => {
        const calculateOffsetTop = () => {
            const navbarHeight = 64; // Adjust as necessary
            const gapSize = 8; // Adjust as necessary
            const additionalOffset = 0; // Any other offsets
            setOffsetTop(navbarHeight + gapSize * 2 + additionalOffset + 150);
        };

        calculateOffsetTop();
        window.addEventListener('resize', calculateOffsetTop);

        return () => {
            window.removeEventListener('resize', calculateOffsetTop);
        };
    }, []);

    const viewRef = useRef(view);
    const nextPageRef = useRef(nextPage);
    const loadingMoreRef = useRef(loadingMore);

    useEffect(() => {
        viewRef.current = view;
    }, [view]);

    useEffect(() => {
        nextPageRef.current = nextPage;
    }, [nextPage]);

    useEffect(() => {
        loadingMoreRef.current = loadingMore;
    }, [loadingMore]);


    const handleFetchNextPage = async () => {
        if (loadingMoreRef.current || loading || !nextPageRef.current) return;
    
        setLoadingMore(true);
        if (viewRef.current === "today") {
            await today.fetchNextTodayPage();
        } else if (viewRef.current === "yesterday") {
            await yesterday.fetchNextYesterdayPage();
        } else if (viewRef.current === "month") {
            await month.fetchNextMonthPage();
        } else if (viewRef.current === "year") {
            await year.fetchNextYearPage();
        } else {
            await ongoing.fetchNextOngoingPage();
        }
        setLoadingMore(false);
      };

    const columns: TableColumn[] = view === "ongoing"
        ? [
            { header: t(''), key: 'row_number' },
            // { header: t('id'), key: 'id' },
            { header: t('start time'), key: 'start' },
            { header: t('duration'), key: 'duration' },
            { header: `${t('energy')} (kWh)`, key: 'energy_kwh' },
            { header: t('charging station'), key: 'charging_station' },
            { header: t('tariff'), key: 'tariff_name' },
            { header: t('amount (incl. VAT)'), key: 'amount' },
            { header: t('user'), key: 'user_name' },
        ]
        : [
            { header: t(''), key: 'row_number' },
            // { header: t('id'), key: 'id' },
            { header: t('start time'), key: 'start_date' },
            { header: t('end time'), key: 'completed_at' }, 
            { header: t('duration'), key: 'duration' },
            { header: `${t('energy')} (kWh)`, key: 'energy_kwh' },
            { header: t('charging station'), key: 'charging_station' },
            { header: t('tariff'), key: 'tariff_name' },
            { header: t('amount (incl. VAT)'), key: 'amount' },
            { header: t('user'), key: 'user_name' },
        ];


    const tableData: TableRow[] = sessions.map((session) => ({
        row_number: sessions.indexOf(session) + 1,
        start_date: session.active_at 
          ? new Date(session.active_at).toLocaleString() : 
          session.start ? new Date(session.start).toLocaleString() :  // use start/created (in case of pending sessions)
          '-',
        completed_at: session.completed_at? new Date(session.completed_at).toLocaleString() : '-',
        duration: session.completed_at
          ? calculateDuration(session.start, session.completed_at)
          : calculateDuration(session.start, new Date().toISOString()),
        energy_kwh: (session.energy / 1000).toFixed(2),
        charging_station: session.chargepoint_name,
        start: new Date(session.start).toLocaleString(),
        tariff_name: session.tariff ? session.tariff : '-',
        amount: session.total_cost ? session.total_cost.toFixed(2) : '0.00',
        user_name: session.user_name ? session.user_name : session.token_uid ? session.token_uid : 'AD HOC USER',
        uuid: session.uuid,
        // id: session.uuid //TODO: remove this
      }));
    
      if (error) {
        return <ErrorPage errorCode={500} errorMessage={error} />;
      }

    return (
      <div>
        <Heading
          title={t('sessions')}
          highlight={t('charging')}
          description={t('Current and historic session details.')}
        />
        <div className="main-content mt-10 text-txt-lg-body dark:text-txt-dk-body">
          <div className="flex justify-left mb-4">
            <ButtonGroup
              view={view}
              buttons={[
                { key: "ongoing", label: "Ongoing" },
                { key: "today", label: "Today" },
                { key: "yesterday", label: "Yesterday" },
                { key: "month", label: "Month" },
                { key: "year", label: "Year" }
              ]}
              setView={setView}
            />
          </div>
          <Table
            table_name="sessions"
            columns={columns}
            data={tableData}
            onRowClick={(row) => navigate(`/sessions/${row.uuid}`)}
            loading={loading && tableData.length === 0}
            loadingMore={loadingMore}
            offsetTop={offsetTop}
            isScrollable={true}
            onLoadMore={handleFetchNextPage}
          />
          {!loading && !nextPage && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <TfiLayoutLineSolid size={32} />
            </div>
          )}
        </div>
      </div>
    );
};

export default SessionsPage;
