import { useState, useEffect, useRef } from 'react';
import { getYearSessions } from '../services/apiService';
import { Components } from '../api/client';

type Session = Components.Schemas.Session;
type ResponseSessionList = Components.Schemas.ResponseFilteredSessionList;

const useYearSessions = () => {
    const [sessions, setSessions] = useState<Session[]>([]);
    const [nextPage, setNextPage] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const fetchSessions = async (nextPageUrl?: string) => {
        try {
            setLoading(true);
            const data: ResponseSessionList = await getYearSessions(nextPageUrl);
            
            setSessions((prev) => {
                const combined = [...prev, ...data.sessions.results];
                const uniqueSessions = Array.from(
                    new Map(combined.map((session) => [session.uuid, session])).values()
                );
                return uniqueSessions;
            });
            setNextPage(data.sessions.next);
        } catch (err) {
            console.error(err);
            setError('Failed to fetch the yearly sessions.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSessions(); // Initial fetch
    }, []);

    const nextPageRef = useRef(nextPage);

    useEffect(() => {
        nextPageRef.current = nextPage; // Sync ref with state
    }, [nextPage]);

    const fetchNextYearPage = async () => {
        if (nextPageRef.current) {
            await fetchSessions(nextPageRef.current);
        }
    };

    return { sessions, loading, error, nextPage, fetchNextYearPage };
};

export default useYearSessions;
