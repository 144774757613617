import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useCardsList from '../hooks/useCardslist';
import { filterData } from '../utils/filterUtils';
import ErrorPage from './ErrorPage';

// components
import Heading from '../components/typography/Heading';
import Table, { TableColumn, TableRow } from '../components/basics/table';
import SearchInput from '../components/SearchInput';

const CardsPage: React.FC = () => {
    const { cards, error, loading } = useCardsList();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState<TableRow[]>([]);

    const cardColumns: TableColumn[] = [
        { header: t("company"), key: "company" },
        { header: t("user"), key: "user" },
        { header: t("number"), key: "number" },
        { header: t("description"), key: "desc" },
        { header: t("status"), key: "enabled" },
    ];

    useEffect(() => {
        // search based on all criterials in the table
        const criteria = { number: searchQuery, company: searchQuery, user: searchQuery, desc: searchQuery, enabled: searchQuery };
        const mappedData = cards
            .map(card => ({
                uuid: card.uuid as string,
                company: card.user?.company || 'N/A',
                number: card.number,
                user: card.user?.email || 'N/A',
                desc: card.desc || '',
                enabled: card.enabled ? "Active" : "Inactive"
            }));
        setFilteredData(filterData(mappedData, criteria));
    }, [cards, searchQuery]);

    if (error) {
        return <ErrorPage errorCode={500} errorMessage={error} />;
    }

    const handleRowClick = (row: TableRow) => {
        navigate(`/cards/${row.uuid}`);
    };

    return (
        <div>
            <Heading
                title={t('Cards')}
                highlight={t('charging')}
                description={t('All the Charging Cards.')}
            />
            <div className="main-content mt-10 text-txt-lg-body dark:text-txt-dk-body flex flex-col items-center">
                <div className="w-full flex justify-center mb-6">
                    <SearchInput
                        id="search"
                        value={searchQuery}
                        onChange={setSearchQuery} // Directly set the search query
                        displayKey="number" // Specify the key to display (kept for clarity)
                    />
                </div>
                <div className="w-full">
                    <Table table_name="Cards" columns={cardColumns} data={filteredData} loading={loading} onRowClick={handleRowClick} />
                </div>
            </div>
        </div>
    );
};

export default CardsPage;
