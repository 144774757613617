import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useUserList from '../hooks/useUserlist';
import { filterData } from '../utils/filterUtils';
import { useNavigate } from 'react-router-dom';

// components
import Heading from '../components/typography/Heading';
import Table, { TableColumn, TableRow } from '../components/basics/table';
import SearchInput from '../components/SearchInput';
import ErrorPage from './ErrorPage';

const UsersPage: React.FC = () => {
  const { users, error, loading } = useUserList();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState<TableRow[]>([]);

  const userColumns: TableColumn[] = [
    { header: t("email"), key: "email" },
    { header: t("name"), key: "name" },
    // { header: t("tenant"), key: "tenant" },
    { header: t("company"), key: "company" },
    { header: t("prioritized charging"), key: "prio" },
  ];

  useEffect(() => {
    // search based on all criterials in the table
    const criteria = { email: searchQuery, name: searchQuery, tenant: searchQuery, prio: searchQuery, company: searchQuery };
    const mappedData = users.map(user => ({
      uuid: user.uuid as string,
      email: user.email,
      name: user.first_name && user.last_name
        ? `${user.first_name} ${user.last_name}`
        : user.first_name || user.last_name || 'N/A',
      // tenant: user.tenant?.name || 'N/A',
      company: user?.company || 'N/A',
      prio: user.prio ? "Yes" : "No"
    }));
    setFilteredData(filterData(mappedData, criteria));
  }, [users, searchQuery]);

  if (error) {
    return <ErrorPage errorCode={500} errorMessage={error} />;
  }

  const handleRowClick = (row: TableRow) => {
    navigate(`/users/${row.uuid}`);
  }

  return (
    <div>
      <Heading
        title={t('Users')}
        highlight={t('Your')}
        description={t('All the Charging Cards.')}
      />
      <div className="main-content mt-10 text-txt-lg-body dark:text-txt-dk-body flex flex-col items-center">
        <div className="w-full flex justify-center mb-6">
          <SearchInput
            id="search"
            value={searchQuery}
            onChange={setSearchQuery} // Directly set the search query
            displayKey="name" // Specify the key to display (this is kept for clarity, although it's not used anymore without suggestions)
          />
        </div>
        <div className="w-full flex-1">
          <div className="table-container w-full">
            <Table table_name="users" columns={userColumns} data={filteredData} loading={loading} onRowClick={handleRowClick} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersPage;
